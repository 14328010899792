import React, {useState} from 'react';
import {Container, Row, Col, Button} from "react-bootstrap";

// ICONS 
import MeatIcon from "../Elements/Icons/ProjectsIcons/steak-refeng.png"
import SausageIcon from "../Elements/Icons/ProjectsIcons/sausage-refeng.png"
import ChickenIcon from "../Elements/Icons/ProjectsIcons/chicken-refeng.png"
import FishIcon from "../Elements/Icons/ProjectsIcons/fish-refeng.png"
import MilkIcon from "../Elements/Icons/ProjectsIcons/milk-refeng.png"
import CheeseIcon from "../Elements/Icons/ProjectsIcons/cheese-refeng.png"
import BreadIcon from "../Elements/Icons/ProjectsIcons/bread-refeng.png"
import ReadyIcon from "../Elements/Icons/ProjectsIcons/food-refeng.png"
import VegsIcon from "../Elements/Icons/ProjectsIcons/vegs-refeng.png"
import PharmaIcon from "../Elements/Icons/ProjectsIcons/pharmacy.png"
import HvacIcon from "../Elements/Icons/ProjectsIcons/hvac-refeng2.png"
import AllIcon from "../Elements/Icons/ProjectsIcons/all-projects2.png"


// CATEGORIES IMPORT  
// import RetailCategory from './ProjectsCategories/retail'
import PharmaCategory from './ProjectsCategories/pharmacy'
import VegsFruitsCategory from './ProjectsCategories/vegsfruits'
import MeatCategory from './ProjectsCategories/meatcategory'
import ChickenCategory from './ProjectsCategories/chickencategory'
import FishCategory from './ProjectsCategories/fishcategory'
import MilkCategory from './ProjectsCategories/milkcategory'
import CheeseCategory from './ProjectsCategories/cheesecategory'
import SausageCategory from './ProjectsCategories/sausagecategory'
import BreadCategory from './ProjectsCategories/breadcategory'
import CuisineCategory from './ProjectsCategories/cuisinecategory'
import AirventCategory from './ProjectsCategories/airventcategory'
import AllCategory from './ProjectsCategories/allcategories'


const Buttons = [
    {
        icon: MeatIcon,
        altName: "",
        category: MeatCategory,
        buttonName: "",
    },
    {
        icon: SausageIcon,
        altName: "",
        category: SausageCategory,
        buttonName: "",
    },
    {
        icon: ChickenIcon,
        altName: "",
        category: ChickenCategory,
        buttonName: "",
    },
    {
        icon: FishIcon,
        altName: "",
        category: FishCategory,
        buttonName: "",
    },
    {
        icon: MilkIcon,
        altName: "",
        category: MilkCategory,
        buttonName: "",
    },
    {
        icon: CheeseIcon,
        altName: "",
        category: CheeseCategory,
        buttonName: "",
    },
    {
        icon: BreadIcon,
        altName: "",
        category: BreadCategory,
        buttonName: "",
    },
    {
        icon: ReadyIcon,
        altName: "",
        category: CuisineCategory,
        buttonName: "",
    },
    {
        icon: VegsIcon,
        altName: "",
        category: VegsFruitsCategory,
        buttonName: "",
    },
    {
        icon: PharmaIcon,
        altName: "",
        category: PharmaCategory,
        buttonName: "",
    },
    {
        icon: HvacIcon,
        altName: "",
        category: AirventCategory,
        buttonName: "",
    },
    {
        icon: AllIcon,
        altName: "",
        category: <AllCategory />,
        buttonName: "sds",
    },
];


const RenderProjectsButtons = () => {
    const categories = Buttons.map((item) => <>{item.category}</>)
    console.log(categories[11].props.children);
    const [category, setCategory] = useState(categories[11].props.children);
    
    // function showCategory(category) {
        
    //     setCategory(category);
    // }

    const buttons = Buttons.map((item) => (
        <>
            <Col className="btn-squared-block" xl={3} lg={3} md={3} sm={3} xs={4} >
                <Button variant="squared" size="xxl">
                    <img src={item.icon} className="projects-btn-icon" alt=" " />
                </Button>
                <div>{item.buttonName}</div>
            </Col>
        </>
    ))

    
 
    return  <>
                {buttons}
                {category}
            </>
    
}



const allButtons = () => {
   

    return <Container fluid>
                <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                    <Row style={{paddingBottom: "3vh", paddingLeft: "3vw", paddingTop: "0",}}>
                        <RenderProjectsButtons />
                    </Row>
                </Col>
            </Container>
}

export default allButtons;